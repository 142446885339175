import axios from "axios";

// axios.defaults.baseURL= 'http://192.168.31.189:9001/api/'
// axios.defaults.baseURL= 'https://yunweibang.nengliangkeji.com/api/'
axios.interceptors.request.use(config => {
    // NProgress.start();
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    config.headers['Accept-Language'] = 'zh-CN'
    // config.headers['Authorization'] =localStorage.getItem('token')
    return config;
  }
  , error => {
    return error
  });
  axios.interceptors.response.use(response => {
    if (response.data.code === 401) {
      router.push('/login')
      return;
    }
    return response
  }, error => {
    // debugger
    if (error.response.status === 401) {
    //   Element.Message.error('token或签名失效')
      router.push('/login')
    }
  })

   export default axios
