import axios from "../http";
//根据用户获取菜单
export const getUserMenu=async(data)=>{ 
    let res=await axios.post('/engineeringMenu/getUserMenu',data)
    return res
   }
export const getqueryMenuTreeList=async()=>{ 
    let res=await axios.post('/engineeringMenu/queryMenuTreeList',{})
    return res
   }
 
export const getMenu=async()=>{ 
    let res=await axios.get('/menuInfo')
    return res
   }
//添加菜单
export const addMenu=async(data)=>{ 
 let res=await axios.post('/engineeringMenu/add',data)
 return res
}
//更新菜单
export const putMenu=async(data)=>{ 
    let res=await axios.post('/engineeringMenu/update',data)
    return res
   }
//删除菜单
export const deleteMenu=async(id)=>{ 
    let res=await axios.delete('/engineeringMenu/delete',{id:id})
    return res
}