<template>
  <div class="menuPermission right-main-index" v-loading="loading">

    <!-- 内容主体 -->
    <div class="rmindex-cnt">
      <el-row>
        <!-- 左侧树结构 -->
        <el-col :xs="6" :sm="6" :md="5" :lg="5" class="tree-left">
          <aside class="left-box">
            <div class="left-list-box">
              <div>
                <!-- 搜索 -->
                <el-form>
                  <el-form-item class="left-search">
                    <el-row type="flex" class="row-bg" justify="space-between">
                      <el-input
                        placeholder="菜单名称"
                        clearable
                        v-model="filterText"
                      >
                        <el-button
                          slot="append"
                          icon="el-icon-search"
                          @click="filterMenu(filterText)"
                        ></el-button>
                      </el-input>
                    </el-row>
                  </el-form-item>
                </el-form>
                <!-- 树形结构 -->
                <div class="syste-list mt10">
                  <el-tree
                    :data="setTree"
                    node-key="id"
                    :default-expanded-keys="[0]"
                    :default-checked-keys="defaultKyes"
                    :current-node-key="529"
                    :props="defaultProps"
                    :filter-node-method="filterNode"
                    ref="tree"
                    :highlight-current="treeFlag"
                    :expand-on-click-node="false"
                    @node-click="handleNodeClick"
                  >
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>{{ node.label }}</span>
                      <span class="treeTool">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="添加"
                          placement="bottom"
                        >
                          <el-button
                            type="text"
                            size="mini"
                            icon="el-icon-document-add"
                            @click="() => append(data)"
                          >
                          </el-button>
                        </el-tooltip>
                        <span v-if="node.label != '菜单列表'">
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="删除"
                            placement="bottom"
                          >
                            <el-button
                              type="text"
                              size="mini"
                              icon="el-icon-delete"
                              @click="() => remove(node, data)"
                            >
                            </el-button>
                          </el-tooltip>
                        </span>
                      </span>
                    </span>
                  </el-tree>
                </div>
              </div>
            </div>
          </aside>
        </el-col>
        <!-- 右侧对应内容 -->
        <el-col :xs="18" :sm="18" :md="19" :lg="19" class="tree-right">
          <div class="right-box">
            <!-- 基本信息 -->
            <div class="right-info-box">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-button
                          type="primary"
                          size="small"
                          @click="submitForm('menuForm')"
                          >保存</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="menuForm"
                  :rules="rules"
                  ref="menuForm"
                  label-width="150px"
                  size="mini"
                >
                  <el-row>
                    <el-col  :sm="24" :md="24" :lg="12">
                      <el-form-item :label="menuForm.menuType==1?'菜单名称':'操作名称'" prop="menuName">
                        <el-input v-model="menuForm.menuName"></el-input>
                      </el-form-item>
                    </el-col>
                <el-col v-if="menuForm.menuType==2" :sm="24" :md="24" :lg="12">
                      <el-form-item label="操作标识"  prop="authCode">
                   <el-input v-model="menuForm.authCode"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="menuForm.menuType==1"  :sm="24" :md="24" :lg="12">
                      <el-form-item label="菜单路由" prop="menuPath">
                        <el-input v-model="menuForm.menuPath"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="menuForm.menuType==1"  :sm="24" :md="24" :lg="12">
                      <el-form-item label="菜单排序">
                        <el-input-number
                          v-model="menuForm.sort"
                          controls-position="right"
                          @change="handleSortChange"
                          :min="1"
                          :max="100"
                        ></el-input-number>
                      </el-form-item>
                    </el-col>
                
                    <el-col v-if="menuForm.menuType==1"  :sm="24" :md="24" :lg="12">
                      <el-form-item label="菜单图标">
                       <el-upload
                          class="avatar-uploader"
                          :action="$uploadURL"
                          :show-file-list="false"
                          :on-success="handleUploadSuccess"
                          :before-upload="beforeUpload"
                        >
                          <img  
                            v-if="menuForm.iconUrl"
                            :src="menuForm.iconUrl"
                            class="avatar" style="width:100px;height:100px"
                          />
                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                          ></i> 
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="打开方式" prop="openStyle">
                        <el-select
                          v-model="menuForm.openStyle"
                          placeholder="请选择"
                          class="wp100"
                        >
                          <el-option
                            label="内嵌iframe打开"
                            value="on"
                          ></el-option>
                          <el-option
                            label="自动加载打开"
                            value="off"
                          ></el-option>
                          <el-option label="新窗口打开" value="off"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col> -->
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 添加菜单节点弹出框 -->
      <el-dialog title="菜单列表" :visible.sync="menuDialog">
        <el-form
          :model="menuDialogForm"
          :rules="rules"
          ref="menuDialogForm"
          label-width="100px"
          class="dia-gap"
          size="mini"
        >
                  <el-form-item key="1" required label="菜单类型">
             <el-radio-group @change="menuTypeChange" v-model="menuDialogForm.menuType">
     <el-radio :label="1">菜单</el-radio>
     <el-radio :label="2">操作</el-radio>
    </el-radio-group>
          </el-form-item>
             
          <el-form-item key="2"   :label="menuDialogForm.menuType==1?'菜单名称':'操作名称'" prop="menuName">
            <el-input v-model="menuDialogForm.menuName"></el-input>
          </el-form-item>
           <el-form-item key="7" v-show="menuDialogForm.menuType==2"  label="操作标识" prop="menuName">
            <el-input v-model="menuDialogForm.authCode"></el-input>
          </el-form-item> 
          <el-form-item  v-if="menuDialogForm.menuType==1"  key="3" label="菜单路由" prop="menuPath">
            <el-input v-model="menuDialogForm.menuPath"></el-input>
          </el-form-item>
          <el-form-item  v-show="menuDialogForm.menuType==1"  key="4" label="菜单排序">
            <el-input-number
              v-model="menuDialogForm.sort"
              controls-position="right"
              @change="handleSortChange"
              :min="1"
              :max="100"
            ></el-input-number>
          </el-form-item>
          <el-form-item  v-show="menuDialogForm.menuType==1"  key="5" label="菜单图标">
            <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handleAddUploadSuccess"
                :before-upload="beforeUpload"
              >
                <img  
                  v-if="menuDialogForm.iconUrl"
                  :src="menuDialogForm.iconUrl"
                  class="avatar" style="width:100px;height:100px"
                />
                <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                ></i> 
              </el-upload>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" :loading="menuLoading" @click="submitForm('menuDialogForm')"
            >确 认</el-button
          >
          <el-button @click="menuDialog = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getqueryMenuTreeList,addMenu,putMenu} from '../../../utils/api/institution/menu.js'
let id = 200;
export default {
  data() {
    return {
      menuLoading:false,
      loading: true,
      filterText: "", //搜索菜单
      treeFlag: true, //树形结构当前节点高亮
      setTree: [], //菜单树形结构数据
      defaultProps: {
        children: "childrens",
        label: "menuName",
      },
      menuForm: {
        menuType:1,
        authCode:"",
        menuName: "",
        menuPath: "",
        sort: "0",
        iconUrl:"",
        iconStyle: "",
        menuAssd: "",
        openStyle: "",
        id: "",
      }, //基本信息
      rules: {
        menuName: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        menuPath: [
          { required: true, message: "【菜单路由】不能为空", trigger: "blur" },
        ],
      }, // 基本信息校验规则
      menuDialogForm: {
        authCode:"",
        menuType:1,
        menuName: "",
        menuPath: "",
        sort: "0",
        iconUrl: "",
        menuAssd: "",
        openStyle: "",
      }, //添加菜单列表
      menuDialog: false, //控制弹窗显示隐藏
      indexId: "", //首页id
      addData: {}, //添加节点转存数据
      saveAddData: {}, //存入后台的添加节点数据
      userName: "admin",
      defaultKyes: [],
    };
  },
  created() {
    this.getMenuData();
    this.userName = JSON.parse(localStorage.getItem("userInfo")).name;
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    menuTypeChange(){
      this.menuDialogForm.menuName=''
    },
    // 点击搜索按钮
    filterMenu(text) {
      this.$refs.tree.filter(text);
    },
    // 树形组件筛选功能
    filterNode(value, data) {
      if (!value) return true;
      return data.menuName.indexOf(value) !== -1;
    },

    // 树形组件节点点击事件
    handleNodeClick(data) {
      console.log(data)
      this.menuForm.menuName = data.menuName;
      this.menuForm.menuPath = data.link;
      this.menuForm.sort = data.menuOrder;
      this.menuForm.iconUrl = data.iconUrl;
      this.menuForm.id = data.id;
      this.menuForm.menuType=data.menuType
      this.menuForm.authCode=data.authCode
    },

    // 添加节点
    append(data) {
      this.addData = data;
      this.menuDialog = true;
    },

    // 删除用户
    async remove(node, data) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        that.$http.post("engineeringMenu/delete",{id: data.id}).then(function (response) {
          that.$notify.success({
            title: "提示",
            message: "删除成功",
            showClose: true,
          });
          that.setTree = [];
          that.getMenuData();
        });
      }
    },
    //图片上传成功处理
    handleUploadSuccess(res, file){
      this.menuForm.iconUrl = file.response.data.url;
    }, 
     //图片上传成功处理
    handleAddUploadSuccess(res, file){
      this.menuDialogForm.iconUrl = file.response.data.url;
    },   
     //图片上传之前处理
    beforeUpload(file){
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      // return  isLt2M;
    },
    // 组织排序
    handleSortChange(value) {
   
    },

    // 保存
    submitForm(formName) {
      this.$refs[formName].validate( async(valid) => {
        if (valid) {
          if (formName == "menuDialogForm") {
            let data={
              menuName:this.menuDialogForm.menuName,
              menuType:this.menuDialogForm.menuType,
              creator:this.userName
            }
            if(this.menuDialogForm.menuType==1){
              data.link=this.menuDialogForm.menuPath
              data.menuOrder=this.menuDialogForm.sort
              data.iconUrl=this.menuDialogForm.iconUrl
            }
            if(this.menuDialogForm.menuType==2){
              data.authCode=this.menuDialogForm.authCode
            }
            this.saveAddData.menuName = this.menuDialogForm.menuName;
            this.saveAddData.link = this.menuDialogForm.menuPath;
            this.saveAddData.menuOrder = this.menuDialogForm.sort;
            this.saveAddData.iconUrl = this.menuDialogForm.iconUrl;
            this.saveAddData.creator = this.userName;
            this.saveAddData.menuType=this.menuDialogForm.menuType
            this.saveAddData.authCode=this.menuDialogForm.authCode
            // this.saveAddData.menuCode = this.randomString(20);
            if (this.addData.menuName == "菜单列表") {
              this.saveAddData.parentCode = "";
              data.parentCode=''
            } else {
              this.saveAddData.parentCode = this.addData.menuCode;
              data.parentCode= this.addData.menuCode
            }
            this.menuLoading=true
      
         let res=await addMenu(data);
         this.menuLoading=false
         if(res.data.code===200){
                      this.menuDialog = false;
                this.$notify.success({
                  title: "提示",
                  message: "增加成功",
                  showClose: true,
                });
                this.menuDialogForm = {
                  authCode:"",
                  menuType:1,
                  menuName: "",
                  menuPath: "",
                  sort: "",
                  iconStyle: "",
                  menuAssd: "",
                };
                this.setTree = [];
                this.getMenuData();
         }else{
           this.$message.error(res.data.message)
         }
    
          }
          if (formName == "menuForm") {
            var arr = {id: this.menuForm.id,menuName : this.menuForm.menuName};
            if(this.menuForm.menuType==1){
              arr.link = this.menuForm.menuPath;
            arr.menuOrder = this.menuForm.sort;
            arr.iconUrl = this.menuForm.iconUrl;
            }else{
              arr.authCode=this.menuForm.authCode
            }
            // let res=await this.$http.post("/engineeringMenu/save",arr)
            let res=await putMenu(arr);
            if(res.data.code==200){
                this.$notify.success({
                  title: "提示",
                  message: "更改成功",
                  showClose: true,
                });
                this.setTree = [];
                this.getMenuData();
            }else{
              this.$message.error(res.data.message)
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取随机字符串
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },

    // 获取数据
   async getMenuData() {
    let res=await this.$http.post('/engineeringMenu/queryMenuTreeList',{})

    if(res.data.code==200){
             this.defaultKyes.push(res.data.data[0].id);
          // that.$refs.tree.setCheckedKeys(that.defaultKyes);
          this.menuForm.menuName = res.data.data[0].menuName;
          this.menuForm.menuPath = res.data.data[0].link;
          this.menuForm.sort = res.data.data[0].menuOrder;
          this.menuForm.iconUrl = res.data.data[0].iconUrl;
          this.menuForm.id = res.data.data[0].id;
          var arr = res.data.data;
          var newArr = {};
          newArr.id = 0;
          newArr.menuName = "菜单列表";
          newArr.menuCOode = this.randomString(20);
          newArr.childrens = arr;
          this.setTree.push(newArr);

    }else{
      this.$message.error(res.data.message)
    }

    },
    // 转换数据
    translateDataToTree(data) {
      //没有父节点的数据
      var parents = data.filter((value) => value.parentCode == "");

      //有父节点的数据
      var childrens = data.filter((value) => value.parentCode !== "");

      //定义转换方法的具体实现
      var translator = (parents, childrens) => {
        //遍历父节点数据
        parents.forEach((parent) => {
          //遍历子节点数据
          childrens.forEach((current, index) => {
            //此时找到父节点对应的一个子节点
            if (current.parentCode === parent.menuCode) {
              //对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
              var temp = JSON.parse(JSON.stringify(childrens));
              //让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
              temp.splice(index, 1);
              //让当前子节点作为唯一的父节点，去递归查找其对应的子节点
              translator([current], temp);
              //把找到子节点放入父节点的childrens属性中
              typeof parent.childrens !== "undefined"
                ? parent.childrens.push(current)
                : (parent.childrens = [current]);
            }
          });
        });
      };

      //调用转换方法
      translator(parents, childrens);

      //返回最终的结果
      return parents;
    },
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.handle-btn .el-button.add-column-btn {
  width: 100%;
  font-size: 13px;
  background-color: transparent;
  color: #008eff;
  margin-top: -1px;
  position: relative;
  z-index: 100;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.menuPermission {
  .tree-left {
    width: 280px;
    .left-list-box {
      padding: 20px 10px;
      height: calc(100vh - 110px);
      .el-form-item {
        margin-bottom: 0;
        .el-form-item__content {
          line-height: 30px;
        }
      }
      .selma {
        width: 72%;
      }
      .el-button.is-circle {
        margin-left: 7px;
      }
      .syste-list {
        height: calc(100vh - 240px);
        margin-bottom: 20px;
        position: relative;
        overflow: auto;
      }
    }
  }
  .tree-right {
    width: calc(100% - 280px);
    .right-box {
      padding-left: 16px;
      padding-right: 16px;
      height: calc(100vh - 110px);
      overflow: auto;
      border-left: 2px solid #eff4f8;
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #f1fcf8;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 18px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
    }
  }
  .el-dialog {
    margin-top: 15vh;
    width: 600px;
  }
  .el-input-number--mini {
    width: 100%;
  }
  .el-input-number .el-input__inner {
    text-align: left;
  }
  .el-tree{
    background-color: transparent;
    color: #d7d7d7;
  }
}
</style>